import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DOCUMENT_STATUS_TYPES } from '@/common/constants/common';
import { Document } from '@/views/all-documents/interfaces/document.interface';
import { CreateDocumentViaTemplateSchema } from '@/views/all-documents/manage/create-document-via-template-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';

export type Options = Omit<
  UseMutationOptions<Document, DefaultError, CreateDocumentViaTemplateSchema>,
  'mutationKey' | 'mutationFn'
>;

export const useCreateDocumentViaTemplateMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: CreateDocumentViaTemplateSchema): Promise<Document> => {
    const { status, documentType, dueDate } = value;
    const parsedValue = {
      status: status === DOCUMENT_STATUS_TYPES.Expired ? DOCUMENT_STATUS_TYPES['Past due'] : status, // @TODO: Change Past due to Expired on BE
      documentType,
      dueDate,
      therapistId: value.therapist ? JSON.parse(value.therapist).id : null,
      serviceId: value.service ? JSON.parse(value.service).id : null,
      clientId: value.client ? JSON.parse(value.client).id : null,
    };
    const body = _.omitBy(parsedValue, (v) => _.isNil(v) || v === '');
    const { data } = await axiosInterceptorInstance.post(`documents/templated`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['documents', 'templated'],
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['documents'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    },
    mutationFn: _mutation,
    ...options,
  });
};
