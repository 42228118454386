'use client';
import {
  ColumnOrderState,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react';
import { ConfirmDialog, Pagination, toast, ToastAction, ToastTypeEnums } from '@/components/common';
import { Button, Dialog } from '@/components/ui';
import { ColumnAdvance, TableColumnSettings } from '../../../components/common/table/table-column-settings';
import { ConfirmDialogRef } from '@/components/common/confirm-dialog';
import { useTranslations } from 'next-intl';
import { sortOrderValueToAPIParams } from '@/components/common/table/utils';
import { CommonTable } from '@/components/common/table/common-table';
import { SimpleSearch } from '@/components/common/table/simple-search';
import { PreviewGuardianDialog, PreviewGuardianDialogRef } from '../preview/preview-guardian.dialog';
import { DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { ManageGuardianForm } from '../manage/manage-guardian-form';
import { Client, Guardian } from '../interfaces/guardians.interface';
import { useGuardiansQuery } from '@/hooks/fetchers/queries/guardians/useGuardiansQuery';
import { useRemoveGuardianMutation } from '@/hooks/fetchers/mutations/guardians/useRemoveGuardianMutation';
import { useRemoveUndoGuardianMutation } from '@/hooks/fetchers/mutations/guardians/useRemoveUndoGuardianMutation';
import { getAllGuardiansColumns } from './all-guardians-columns';
import { Plus } from 'lucide-react';
import { useIsAllowed } from '@/lib/RBAC';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';
import { cn } from '@/lib/utils';
import { ToggleFilter } from '@/components/common/toggle-filter';
import { useTableSettingsQuery } from '@/hooks/fetchers/queries/useTableSettingsQuery';
import { useTableSettingsMutation } from '@/hooks/fetchers/mutations/useTableSettingsMutation';
import { TableTypes } from '@/common/enums/common';
import { PreviewClientDialog, PreviewClientDialogRef } from '@/views/all-clients/preview/preview-client.dialog';

const PER_PAGE = 200;

interface Params {
  clientId?: string;
  tableClassName?: string;
  hideCreate?: boolean;
  hideLabel?: boolean;
  hideTableSettings?: boolean;
}

export function AllGuardiansTable({ clientId, hideLabel, tableClassName, hideCreate, hideTableSettings }: Params) {
  const t = useTranslations();
  const [data, setData] = useState<Guardian[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [createGuardianDialogOpen, setCreateGuardianDialogOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [showDeleted, setShowDeleted] = useState(false);
  const [pagination, setPagination] = useState<{ page: number; perPage: number }>({
    page: 0,
    perPage: PER_PAGE,
  });
  const previewGuardianDialogRef = createRef<PreviewGuardianDialogRef>();
  const confirmDialogRef = useRef<ConfirmDialogRef>();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const { checkPermissions } = useIsAllowed();

  const { data: tableConfig } = useTableSettingsQuery(TableTypes.ALL_GUARDIANS);
  const { mutate: setTableConfig } = useTableSettingsMutation();

  const { data: tableData, refetch: refetchGuardians } = useGuardiansQuery({
    skip: pagination.page * pagination.perPage,
    take: pagination.perPage,
    search,
    showDeleted,
    clientId: clientId,
    ...sortOrderValueToAPIParams(sorting),
  });

  useEffect(() => {
    if (tableConfig && tableConfig.settings.length > 0) {
      table.getAllLeafColumns().map((c: ColumnAdvance<Guardian>) => {
        const isVisible = tableConfig.settings.find((col) => col.title === c.id)?.isVisible;
        c.toggleVisibility(isVisible); // Change visible columns
      });
      table.setColumnOrder(tableConfig.settings.map((col) => col.title)); // Change order columns by ids
    }
  }, [JSON.stringify(tableConfig)]);

  useEffect(() => {
    if (tableData) {
      setData(tableData?.data);
    }
  }, [tableData]);

  const { mutate: removeGuardian } = useRemoveGuardianMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
        action: (
          <ToastAction onClick={() => removeUndo(data.id)} altText="Cancel remove">
            Undo action
          </ToastAction>
        ),
      });
      refetchGuardians();
    },
  });

  const { mutate: removeUndo } = useRemoveUndoGuardianMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successRestored'),
      });
      refetchGuardians();
    },
  });

  const handleConfirmRemove = (row: Row<Guardian>) => {
    confirmDialogRef.current?.open({
      description: t('Toasts.confirmDeleteGuardian', { name: `${row.original.firstName} ${row.original.lastName}` }),
      value: row.original.id,
    });
  };

  const handlePreviewClient = (client: Client) => {
    previewClientDialogRef.current?.openById(client.id);
  };

  const table = useReactTable({
    columns: getAllGuardiansColumns({
      t,
      onPreviewClient: handlePreviewClient,
      onConfirmRemove: handleConfirmRemove,
      isCanRemove: checkPermissions([PermissionsTypes.DELETE_GUARDIAN]),
    }),
    data,
    debugTable: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy access
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
  });

  const handleChangeColumnSettings = (columnIds: ColumnAdvance<Guardian>[]) => {
    setTableConfig({
      name: TableTypes.ALL_GUARDIANS,
      settings: columnIds.map((col) => ({ isVisible: col.isVisible, title: col.id })) as {
        isVisible: boolean;
        title: string;
      }[],
    });
    const ids = columnIds.map((c) => {
      c.toggleVisibility(c.isVisible); // Change visible columns
      return c.id;
    });
    table.setColumnOrder(ids); // Change order columns by ids
  };

  const handleSearchFilter = (event?: ChangeEvent<HTMLInputElement>) => {
    const search = event?.target.value || '';
    setPagination((prev) => ({
      ...prev,
      page: 0,
    }));
    setSearch(search);
  };

  const columnsIds = table.getAllLeafColumns().map((column: ColumnAdvance<Guardian>) => {
    column.isVisible = column.getIsVisible();
    return column;
  });

  return (
    <>
      <header className="flex flex-row items-center justify-between py-2">
        <h1 className="py-4 text-2xl font-bold">{!hideLabel && t('Pages.AllGuardians.title')}</h1>

        <div className="flex flex-row items-center space-x-2">
          <SimpleSearch onSearchChange={handleSearchFilter} />

          {!hideCreate && checkPermissions([PermissionsTypes.CREATE_GUARDIAN]) ? (
            <Dialog open={createGuardianDialogOpen} onOpenChange={setCreateGuardianDialogOpen}>
              <DialogTrigger asChild>
                <Button size="sm">
                  <Plus className="mr-1 h-4 w-4" /> {t('Buttons.addGuardian')}
                </Button>
              </DialogTrigger>
              <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-[1280px]">
                <DialogHeader>
                  <DialogTitle>{t('Pages.AllGuardians.createGuardian')}</DialogTitle>
                  <DialogDescription></DialogDescription>
                </DialogHeader>
                <ManageGuardianForm
                  onCancel={() => setCreateGuardianDialogOpen(false)}
                  onRefresh={() => {
                    setCreateGuardianDialogOpen(false);
                    refetchGuardians();
                  }}
                />
              </DialogContent>
            </Dialog>
          ) : null}
          {!hideTableSettings && <TableColumnSettings columnsIds={columnsIds} onChange={handleChangeColumnSettings} />}
        </div>
      </header>

      <div className="mb-2 grid h-10 w-full max-w-60 grid-cols-2 gap-1">
        {checkPermissions([PermissionsTypes.VIEW_FILTER_ARCHIVED]) && (
          <>
            <ToggleFilter label={t('Buttons.active')} onChange={() => setShowDeleted(false)} isActive={!showDeleted} />
            <ToggleFilter label={t('Buttons.archived')} onChange={() => setShowDeleted(true)} isActive={showDeleted} />
          </>
        )}
      </div>

      <div className={cn('relative h-[calc(100vh-244px)] max-w-full overflow-auto', tableClassName)}>
        <div className="absolute w-full">
          <CommonTable
            table={table}
            onClickByRow={(rowOriginal) => previewGuardianDialogRef.current?.open(rowOriginal)}
          />
        </div>
      </div>

      {tableData && tableData.totalCount > 0 && (
        <Pagination
          className="mt-1"
          changeCurrentPage={(page) =>
            setPagination((prev) => {
              return {
                ...prev,
                page: page - 1,
              };
            })
          }
          totalSize={tableData && tableData.totalCount ? tableData.totalCount : 0}
          sizePerPage={pagination.perPage}
          currentPage={pagination.page + 1}
        />
      )}

      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => removeGuardian(id)}
        title={t('Pages.AllGuardians.deleteGuardian')}
        type="delete"
      />

      <PreviewGuardianDialog ref={previewGuardianDialogRef} />
      <PreviewClientDialog onUpdated={refetchGuardians} ref={previewClientDialogRef} />
      
    </>
  );
}
