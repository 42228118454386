import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ManageTherapistValidationSchema } from '@/views/all-therapists/manage/manage-therapist-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';

interface RequestData extends Omit<ManageTherapistValidationSchema, 'languages'> {
  languages?: string[];
}

export type Options = Omit<UseMutationOptions<string, DefaultError, RequestData>, 'mutationKey' | 'mutationFn'>;

export const useCreateTherapistMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Partial<RequestData>): Promise<string> => {
    const parsedValue = {
      ...value,
      serviceRoleIdList: value.serviceRoleIdList?.map(role => role.value.id),
      supervisorId: value.supervisor ? JSON.parse(value.supervisor).id : null,
    };

    if (parsedValue.supervisor) {
      delete parsedValue.supervisor;
    }

    const body = _.omitBy(parsedValue, v => _.isNil(v) || v === '');
    const { data } = await axiosInterceptorInstance.post(`therapists`, body);
    return data;
  };

  return useMutation({
    mutationKey: ['therapists'],
    mutationFn: _mutation,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['therapists'] });
        queryClient.invalidateQueries({ queryKey: ['activity-logs'] });
      }
    },
    ...options,
  });
};
