export const ALPHABETIC_NUMBERS: RegExp = /^[a-zA-Z0-9\s]+$/;
export const ONLY_ALPHABETIC: RegExp = /^[a-zA-Z\s]+$/;
export const UPPER_AND_LOWER_CASE: RegExp = /(?=.*[a-z])(?=.*[A-Z])/g;
export const TIME_12h: RegExp = /^(0[1-9]|1[0-2]):[0-5][0-9] ?[APap][Mm]$/;
export const SPECIAL_AND_NUMBERS: RegExp = /(?=.*[0-9])(?=.*[,$&+,:;=?@#|'<>.^*()%!-\s_/\\\*])/g;
export const DATE_MMDDYYYY: RegExp = /^(0[1-9]|1[0-2])\.(0[1-9]|[12][0-9]|3[01])\.(\d{4})$/g;
export const ALLOWED_DOMAIN =
  process.env.NEXT_PUBLIC_ENV === 'stage' ||
  process.env.NEXT_PUBLIC_ENV === 'develop' ||
  process.env.NEXT_PUBLIC_ENV === 'local'
    ? '@s-pro.io'
    : '@maximatherapy.com';
