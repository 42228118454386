import { Chips, LabelValueItem, ScrollArea, toast, ToastTypeEnums } from '@/components/common';
import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';

import { useTranslations } from 'next-intl';
import { createRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Document } from '../interfaces/document.interface';
import { Colors } from '@/common/enums';
import { useIsAllowed } from '@/lib/RBAC';
import { compileFullName, getColorForServiceRole, transformApiDateToViewDate } from '@/lib/utils';
import { useApproveDocumentMutation } from '@/hooks/fetchers/mutations/documents/useApproveDocumentMutation';
import { useRejectDocumentMutation } from '@/hooks/fetchers/mutations/documents/useRejectDocumentMutation';
import { FileText } from 'lucide-react';
import { DOCUMENT_STATUS_TYPES } from '@/common/constants/common';
import { useSubmitDocumentMutation } from '@/hooks/fetchers/mutations/documents/useSubmitDocumentMutation';
import { PreviewClientDialog, PreviewClientDialogRef } from '@/views/all-clients/preview/preview-client.dialog';
import {
  PreviewTherapistDialog,
  PreviewTherapistDialogRef,
} from '@/views/all-therapists/preview/preview-therapist.dialog';
import { PreviewServiceDialog, PreviewServiceDialogRef } from '@/views/all-services/preview/preview-service.dialog';
import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { AcquireFileType } from '../acquire-file-types';
import { useDocumentQuery } from '@/hooks/fetchers/queries/documents/useDocumentQuery';
import { USER_ROLES_COLORS } from '@/common/constants/chips-colors';
import { RoleTypes } from '@/lib/RBAC/enums/role-types';
import { useSession } from 'next-auth/react';

interface Props {
  onUpdated?: () => void;
}

export type PreviewDocumentDialogRef = {
  open: (document: Document) => void;
  openById: (id: string) => void;
};

const PreviewDocumentDialog = forwardRef<PreviewDocumentDialogRef, Props>(({ onUpdated }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const previewServiceDialogRef = createRef<PreviewServiceDialogRef>();
  const previewClientDialogRef = createRef<PreviewClientDialogRef>();
  const previewTherapistDialogRef = createRef<PreviewTherapistDialogRef>();
  const [documentData, setDocumentData] = useState<Document | null>(null);
  const [documentId, setDocumentId] = useState<string>();
  const { data: session } = useSession();

  const { data: documentDataById, refetch: refetchDocumentDataById } = useDocumentQuery(documentId, { refetchOnWindowFocus: isOpen });

  useEffect(() => {
    if (documentDataById) {
      setDocumentData(documentDataById);
    }
  }, [documentDataById]);

  useImperativeHandle(ref, () => ({
    open: (document) => {
      setIsOpen(true);
      setDocumentData(document);
      setDocumentId(document.id);
    },
    openById: (id) => {
      setIsOpen(true);
      setDocumentId(id);
    },
  }));

  useEffect(() => {
    if (!isOpen && documentId) {
      setDocumentId(undefined);
    }
  }, [isOpen]);

  const { mutate: approve, isPending: isPendingApprove } = useApproveDocumentMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.approveDoc'),
      });
      onUpdated?.();
      setIsOpen(false);
    },
  });

  const openReportFile = async () => {
    const { data } = await axiosInterceptorInstance.get(
      `documents/${documentData?.id}/acquire/${AcquireFileType.Report}`
    );
    if (data?.url) {
      window.open(data.url, '_blank', 'noopener,noreferrer');
    } else {
      toast({
        title: t('Toasts.warning'),
        typeIcon: ToastTypeEnums.WARNING,
        description: t('Toasts.notFoundFile'),
      });
    }
  };

  const openNotesFile = async () => {
    const { data } = await axiosInterceptorInstance.get(
      `documents/${documentData?.id}/acquire/${AcquireFileType.Notes}`
    );
    if (data?.url) {
      window.open(data.url, '_blank', 'noopener,noreferrer');
    } else {
      toast({
        title: t('Toasts.warning'),
        typeIcon: ToastTypeEnums.WARNING,
        description: t('Toasts.notFoundFile'),
      });
    }
  };

  const { mutate: submit, isPending: isPendingSubmit } = useSubmitDocumentMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.submitDoc'),
      });
      onUpdated?.();
      setIsOpen(false);
    },
  });

  const { mutate: reject, isPending: isPendingReject } = useRejectDocumentMutation({
    onSuccess: (data) => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.rejectDoc'),
      });
      onUpdated?.();
      setIsOpen(false);
    },
  });

  const previewClient = (id: string) => {
    previewClientDialogRef.current?.openById(id);
  };

  const previewTherapist = (id: string) => {
    previewTherapistDialogRef.current?.openById(id);
  };

  const previewService = (id: string) => {
    previewServiceDialogRef.current?.openById(id, false);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className="w-full max-w-[1280px]">
          <DialogHeader>
            <DialogTitle>{t('Pages.AllDocuments.previewDocument')}</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <ScrollArea className="h-[calc(100vh-260px)] overflow-auto pr-5">
            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <div
                onClick={() => openReportFile()}
                className="flex min-h-14 max-w-44 cursor-pointer flex-row items-center justify-start space-x-4 rounded-sm transition-colors duration-300 hover:bg-blue-50"
              >
                <div className="flex flex-col items-center justify-center rounded-sm bg-blue-100 p-2">
                  <FileText className="h-8 w-8 text-blue-600" />
                </div>
                <span className="text-base">{documentData?.fileUrl ? documentData?.fileName : t('Buttons.reportFile')}</span>
              </div>
              <div
                onClick={() => openNotesFile()}
                className="flex min-h-14 max-w-44 cursor-pointer flex-row items-center justify-start space-x-4 rounded-sm transition-colors duration-300 hover:bg-blue-50"
              >
                <div className="flex flex-col items-center justify-center rounded-sm bg-blue-100 p-2">
                  <FileText className="h-8 w-8 text-blue-600" />
                </div>
                <span className="text-base">{documentData?.notesFileName ? documentData?.notesFileName : t('Buttons.notesFile')}</span>
              </div>
            </div>
            <div className="my-6 flex w-full max-w-[676px] flex-col space-y-6">
              {documentData?.client && (
                <LabelValueItem label={t('Forms.client.label')} withoutBorder>
                  <Chips
                    onClickAction={
                      checkPermissions([PermissionsTypes.VIEW_ALL_DOCUMENTS]) ||
                      session?.user.userId === documentData.therapist?.id // only for assigned therapist
                        ? () => previewClient(documentData.client.id)
                        : undefined
                    }
                    color={Colors.indigo}
                    title={compileFullName(documentData?.client)}
                  />
                </LabelValueItem>
              )}

              {documentData?.therapist && (
                <LabelValueItem label={t('Forms.therapist.label')} withoutBorder>
                  <Chips
                    color={USER_ROLES_COLORS[RoleTypes.Therapist]}
                    title={compileFullName(documentData?.therapist)}
                    onClickAction={
                      checkPermissions([PermissionsTypes.VIEW_ALL_DOCUMENTS]) && checkPermissions([PermissionsTypes.PREVIEW_THERAPIST_PROFILE])
                        ? () => previewTherapist(documentData.therapist?.id as string)
                        : undefined
                    }
                  />
                </LabelValueItem>
              )}

              {documentData?.service && documentData?.service.serviceRole ?  (
                <LabelValueItem label={t('Forms.service.label')} withoutBorder>
                  <Chips
                    color={getColorForServiceRole(documentData?.service.serviceRole.title)}
                    title={documentData.service.name}
                    onClickAction={
                      checkPermissions([PermissionsTypes.VIEW_ALL_DOCUMENTS]) ||
                      session?.user.userId === documentData.therapist?.id // only for assigned therapist
                        ? () => previewService(documentData.service?.id as string)
                        : undefined
                    }
                  />
                </LabelValueItem>
              ) : null}
            </div>
            <div className="grid grid-cols-form-cols-2 gap-8 pl-1">
              <LabelValueItem label={t('Forms.reportType.label')} value={documentData?.type} />
              {/* @TODO: Change Past due to Expired on BE */}
              <LabelValueItem label={t('Forms.status.label')} value={documentData?.status === DOCUMENT_STATUS_TYPES['Past due'] ? 'Expired' : documentData?.status} /> 
              <LabelValueItem
                label={t('Forms.dueDate.label')}
                value={transformApiDateToViewDate(documentData?.expiryDate)}
              />
            </div>
          </ScrollArea>
          <DialogFooter className="flex h-20 flex-row items-center justify-between space-x-2 border-t border-t-gray-300 sm:justify-between">
            {!documentData?.deletedAt && (
              <>
                {checkPermissions([PermissionsTypes.APPROVE_OR_REJECT_REPORT]) && (
                  <div className="space-x-2">
                    {documentData?.status === DOCUMENT_STATUS_TYPES.Submitted && (
                      <>
                        <Button
                          type="button"
                          size="lg"
                          variant="outlineDestructive"
                          className="mt-5"
                          onClick={() => reject(documentData?.id as string)}
                          disabled={isPendingReject}
                        >
                          {t('Buttons.reject')}
                        </Button>
                        <Button
                          type="button"
                          size="lg"
                          variant="outline"
                          className="mt-5"
                          onClick={() => approve(documentData?.id as string)}
                          disabled={isPendingApprove}
                        >
                          {t('Buttons.approve')}
                        </Button>
                      </>
                    )}
                  </div>
                )}
                <div>
                  {checkPermissions([PermissionsTypes.SUBMIT_DOCUMENT]) &&
                  (documentData?.status === DOCUMENT_STATUS_TYPES.Pending ||
                    documentData?.status === DOCUMENT_STATUS_TYPES['Past due']) ? (
                    <Button
                      type="button"
                      size="lg"
                      className="mt-5"
                      onClick={() => submit(documentData?.id as string)}
                      disabled={isPendingSubmit}
                    >
                      {t('Buttons.submit')}
                    </Button>
                  ) : null}
                </div>
              </>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <PreviewClientDialog onUpdated={refetchDocumentDataById} ref={previewClientDialogRef} />
      <PreviewTherapistDialog onUpdated={refetchDocumentDataById} ref={previewTherapistDialogRef} />
      <PreviewServiceDialog onUpdated={refetchDocumentDataById} ref={previewServiceDialogRef} />
    </>
  );
});

PreviewDocumentDialog.displayName = 'PreviewDocumentDialog';

export { PreviewDocumentDialog };
