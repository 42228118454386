import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { ManageTherapistValidationSchema } from '@/views/all-therapists/manage/manage-therapist-validation-schema';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';

interface Request extends ManageTherapistValidationSchema {
  id: string;
};

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useUpdateTherapistMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (value: Request): Promise<string> => {
    const parsedValue = {
      firstName: value.firstName ?? '',
      lastName: value.lastName ?? '',
      email: value.email ?? '',
      phoneNumber: value.phoneNumber ? value.phoneNumber.replace('+', '') : '',
      supervisorId: value.supervisor ? JSON.parse(value.supervisor).id : null,
      role: value.role ?? '',
      serviceRoleIdList: value.serviceRoleIdList.map((role) => role.value.id),
      languages: value.languages.map((value) => value.label),
    };

    const body = _.omitBy(parsedValue, (v) => _.isNil(v) || v === '');
    const { data } = await axiosInterceptorInstance.put(`therapists/${value.id}`, body);
    queryClient.invalidateQueries({ queryKey: ['therapists', value.id] });
    queryClient.invalidateQueries({ queryKey: ['therapists'] });
    queryClient.invalidateQueries({ queryKey: ['services'] });
    queryClient.invalidateQueries({ queryKey: ['clients'] });
    queryClient.invalidateQueries({ queryKey: ['documents'] });
    queryClient.invalidateQueries({ queryKey: ['shifts'] });
    queryClient.invalidateQueries({ queryKey: ['activity-logs'] });

    return data;
  };

  return useMutation({
    mutationKey: ['therapists'],
    mutationFn: _mutation,
    ...options,
  });
};
