import { ColumnDef, Row } from '@tanstack/react-table';
import dayjs from '@/lib/dayjsConfig';
import { Trash2 } from 'lucide-react';
import { Client, Guardian, Service, Therapist } from '../interfaces/services.interface';
import { Chips } from '@/components/common';
import { Colors, SPECIALITY_CODES } from '@/common/enums';
import { cn, compileFullName, extractFirstPart, getColorForServiceRole } from '@/lib/utils';
import { SERVICES_STATUS_COLORS, SERVICES_TYPE_COLORS, USER_ROLES_COLORS } from '@/common/constants/chips-colors';
import { RoleTypes } from '@/lib/RBAC/enums/role-types';
import { PermissionsTypes } from '@/lib/RBAC/enums/permissions-types';

type Params = {
  t: any;
  onConfirmRemove: (row: Row<Service>) => void;
  onPreviewClient: (client: Client) => void;
  onPreviewGuardian: (guardian: Guardian) => void;
  onPreviewTherapist: (therapist: Therapist) => void;
  checkPermissions: (permissions: PermissionsTypes[]) => boolean
};

export const getAllServicesColumns = ({
  t,
  onConfirmRemove,
  onPreviewClient,
  onPreviewGuardian,
  onPreviewTherapist,
  checkPermissions
}: Params): ColumnDef<Service>[] => {
  const columns: ColumnDef<Service>[] = [
    {
      id: 'Title',
      accessorKey: 'title',
      header: () => <span>{t('Tables.title')}</span>,
      size: 250, //starting column size
    },
    {
      id: 'Client',
      header: () => <span>{t('Tables.client')}</span>,
      cell: ({ row }) => {
        if (row.original.client) {
          return (
            <Chips
              onClickAction={(e) => {
                onPreviewClient(row.original.client);
                e.stopPropagation();
              }}
              color={Colors.indigo}
              title={compileFullName(row.original.client)}
            />
          );
        }
        return <></>;
      },
      size: 250,
      enablePinning: false,
      enableSorting: false,
    },
    {
      id: 'Service Role',
      header: () => <span>{t('Tables.serviceRole')}</span>,
      cell: ({ row }) => {
        if (row.original.serviceRole) {
          const sr = row.original.serviceRole;
          return <Chips color={getColorForServiceRole(sr.title, sr.speciality.code)} title={sr.title} />;
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Guardian',
      accessorKey: 'type',
      header: () => <span>{t('Tables.guardian')}</span>,
      cell: ({ row }) => {
        if (row.original.guardians) {
          return (
            <div className="flex flex-row flex-wrap gap-1 py-2">
              {row.original.guardians.map((guardian) => (
                <Chips
                  key={guardian.id}
                  onClickAction={(e) => {
                    onPreviewGuardian(guardian);
                    e.stopPropagation();
                  }}
                  color={USER_ROLES_COLORS[RoleTypes.Guardian]}
                  title={compileFullName(guardian)}
                />
              ))}
            </div>
          );
        } else {
          return <></>;
        }
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Therapist',
      accessorKey: 'type',
      header: () => <span>{t('Tables.therapist')}</span>,
      cell: ({ row }) => {
        if (row.original.therapist) {
          return (
            <Chips
              onClickAction={
                checkPermissions([PermissionsTypes.PREVIEW_THERAPIST_PROFILE])
                  ? (e) => {
                      onPreviewTherapist(row.original.therapist as Therapist);
                      e.stopPropagation();
                    }
                  : undefined
              }
              color={USER_ROLES_COLORS[RoleTypes.Therapist]}
              title={compileFullName(row.original.therapist)}
            />
          );
        } 
        return <></>;
      },
      size: 250,
    },
    {
      id: 'Stage',
      accessorKey: 'stage',
      header: () => <span>{t('Tables.stage')}</span>,
      cell: ({ row }) => {
        if (row.original.status) {
          const color = (SERVICES_STATUS_COLORS as any)[row.original.status];
          if (color) {
            return <Chips color={color} title={row.original.status} />;
          }
        }
        return <></>;
      },
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Frequency',
      accessorKey: 'frequency',
      header: () => <span>{t('Tables.frequency')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 140,
    },
    {
      id: 'Monthly',
      accessorKey: 'monthly',
      header: () => <span>{t('Tables.monthly')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 140,
    },
    {
      id: 'Authorized From',
      accessorKey: 'authorizedFrom',
      accessorFn: (row) => (row.authorizedFrom ? dayjs(row.authorizedFrom).format('MM.DD.YYYY') : ''),
      header: () => <span>{t('Tables.authorizedFrom')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 140,
    },
    {
      id: 'Authorized To',
      accessorKey: 'authorizedTo',
      accessorFn: (row) => dayjs(row.authorizedTo).format('MM.DD.YYYY'),
      header: () => <span>{t('Tables.authorizedTo')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 130,
    },
    {
      id: 'Date added',
      accessorKey: 'createdAt',
      accessorFn: (row) => (row.createdAt ? dayjs(row.createdAt).format('MM.DD.YYYY') : '---'),
      header: () => <span>{t('Tables.dateAdded')}</span>,
      enableSorting: true,
      enablePinning: false,
      size: 140,
    },
    {
      id: 'Hours worked',
      accessorKey: 'hoursProvided',
      accessorFn: (row) => (row.hoursProvided ? row.hoursProvided : '---'),
      header: () => <span>{t('Tables.hoursWorked')}</span>,
      enableSorting: true,
      enablePinning: false,
      size: 150,
    },
    {
      id: 'Initial visit',
      accessorKey: 'initialVisitDate',
      accessorFn: (row) => (row.initialVisitDate ? dayjs(row.initialVisitDate).format('MM.DD.YYYY') : '---'),
      header: () => <span>{t('Tables.initialVisit')}</span>,
      enableSorting: true,
      enablePinning: false,
      size: 150,
    },
    {
      id: 'Languages',
      header: () => <span>{t('Tables.languages')}</span>,
      cell: ({ row }) => {
        if (row.original.languages) {
          return <span className="">{row.original.languages.join(', ')}</span>;
        } else {
          return <span></span>;
        }
      },
      enableSorting: false,
      enablePinning: false,
      size: 100,
    },
    {
      id: 'Address',
      accessorKey: 'address',
      header: () => <span>{t('Tables.address')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 250,
    },
    {
      id: 'Type',
      accessorKey: 'type',
      header: () => <span>{t('Tables.type')}</span>,
      cell: ({ row }) => {
        if (row.original.type) {
          return <Chips color={SERVICES_TYPE_COLORS[row.original.type]} title={row.original.type} />;
        } else {
          return <></>;
        }
      },
      enableSorting: false,
      enablePinning: false,
      size: 140,
    },
    {
      id: 'In Person Only',
      accessorKey: 'inPersonOnly',
      header: () => <span>{t('Tables.inPersonOnly')}</span>,
      accessorFn: (row) => (row.inPersonOnly ? 'YES' : 'NO'),
      enableSorting: false,
      enablePinning: false,
      size: 140,
    },
    {
      id: 'Program Number',
      accessorKey: 'programNumber',
      header: () => <span>{t('Tables.programNumber')}</span>,
      enableSorting: false,
      enablePinning: false,
      size: 150,
    },
  ];

  if (checkPermissions([PermissionsTypes.SOFT_DELETE_SERVICE])) {
    columns.push({
      id: 'Remove',
      enableSorting: false,
      cell: ({ row }) => (
        <Trash2
          onClick={(e) => {
            if (!row.original.deletedAt) {
              onConfirmRemove(row);
            }
            e.stopPropagation();
          }}
          className={cn(
            'w-[18px] cursor-pointer text-gray-400',
            row.original.deletedAt && 'cursor-not-allowed opacity-45'
          )}
        />
      ),
      size: 50,
    });
  }
  return columns;
};
