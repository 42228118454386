import { Button, Dialog } from '@/components/ui';
import { DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { DialogDescription } from '@radix-ui/react-dialog';

import { useTranslations } from 'next-intl';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ConfirmDialog } from '@/components/common';
import { ConfirmDialogRef } from '@/components/common/confirm-dialog';
import { Therapist } from '../interfaces/therapists.interface';
import { TherapistProfileTabs } from '../enums/therapist-profile-tabs';
import { UpdateTherapistForm } from './update-therapist-form';
import { UpdatePayRatesForm } from './update-pay-rates-form';
import { ManageTherapistValidationSchema } from './manage-therapist-validation-schema';

interface Props {
  onUpdated: () => void;
  onDelete: (id: string) => void;
}

export type ManageTherapistDialogRef = {
  open: (account: Therapist, tab?: TherapistProfileTabs) => void;
};

const ManageTherapistDialog = forwardRef<ManageTherapistDialogRef, Props>(({ onUpdated, onDelete }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const t = useTranslations();
  const accountRef = useRef<Therapist | null>(null);
  const confirmDialogRef = useRef<ConfirmDialogRef>();
  const [defaultTab, setDefaultTab] = useState<TherapistProfileTabs>(TherapistProfileTabs.INFO);

  useImperativeHandle(ref, () => ({
    open: (account, tab) => {
      setIsOpen(true);
      accountRef.current = account;
      if (tab) {
        setDefaultTab(tab);
      } else {
        setDefaultTab(TherapistProfileTabs.INFO);
      }
    },
  }));

  const handleDeleteAccount = (account: ManageTherapistValidationSchema) => {
    if (accountRef.current && accountRef.current.id) {
      const value = {...accountRef.current, ...account };
      confirmDialogRef.current?.open({
        description: t('Toasts.confirmDeleteAccount', { name: `${value.firstName} ${value.lastName}` }),
        value: value.id,
      });
    }
  };

  const handleUpdateAccount = () => {
    setIsOpen(false);
    onUpdated();
  };

  const handleUpdatePayRates = () => {
    setIsOpen(false);
    onUpdated();
  };

  return (
    <>
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent onOpenAutoFocus={(e) => e.preventDefault()} className='w-full max-w-[1280px]'>
        <DialogHeader>
          <DialogTitle>{t('Pages.AllTherapists.therapistProfile')}</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <Tabs defaultValue={defaultTab}>
          <TabsList className="w-full max-w-[300px]">
            <TabsTrigger className="w-full" value={TherapistProfileTabs.INFO}>
              {t('Buttons.profileInfo')}
            </TabsTrigger>
            <TabsTrigger className="w-full" value={TherapistProfileTabs.PAY_RATE}>
              {t('Buttons.payRate')}
            </TabsTrigger>
          </TabsList>
          <TabsContent value={TherapistProfileTabs.INFO} className='pt-4'>
            {accountRef.current && <UpdateTherapistForm account={accountRef.current} onDelete={handleDeleteAccount} onUpdated={handleUpdateAccount} />}
          </TabsContent>
          <TabsContent value={TherapistProfileTabs.PAY_RATE} className='pt-4'>
            {accountRef.current && <UpdatePayRatesForm therapist={accountRef.current} onUpdated={handleUpdatePayRates} />}
          </TabsContent>
        </Tabs>
            
      </DialogContent>
    </Dialog>

    <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => {
          onDelete(id);
          setIsOpen(false);
        }}
        title={t('Common.deleteAccount')}
        type="delete"
      />
    </>
  );
});

ManageTherapistDialog.displayName = 'ManageTherapistDialog';

export { ManageTherapistDialog };
